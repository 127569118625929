@import url(https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap);
/* new */
button{
    cursor: pointer;
}
button.btn-active, button.button-primary:hover {
    background: #44a48a !important;
    border-color: #44a48a !important;
    color: #fff;
}
.btn-wrap.group-btn{
    display: inline-flex;
}
.group-btn.btn-wrap .button:first-child{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.group-btn.btn-wrap .button:nth-child(2) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
}
.kado-btn,
.button.btn-with-brand{
    border: 1px solid #d0d0d0;
    height: 2.8rem;
    margin: 0;
    padding: .3em .7em;
    border-radius: 5px;
    background: #fff;
    font-weight: 500;
    min-width: 50px;
    font-size: 1rem;
    color: #51af96;
}
.kado-btn{
    height: auto;
}
button.btn-active {
    color: #fff !important;
}
.button.btn-with-brand.button-inactive {
    color: #a3a3a4!important;
    pointer-events: none;
}
.print-pdf-div{
    /* margin-left: auto; */
}
.pdf-dwn-btn, .print-btn {
    cursor: pointer;
    border: none;
    color: #595959;
    font-size: 1.1rem;
    padding: 10px;
    border-radius: 5px;
    background: #F6F6F6;
    width: 2.8rem;
    height: 2.8rem;
    transition: all .3s linear;
}
.pdf-dwn-btn:hover, .print-btn:hover{
    background: #ececec;
} 
.button-icon {  
    font-size: 14px;
    color: #6f6f6f;
    display: inline;
    margin: 0px 0px;
    padding: 3px 3px;
    cursor: pointer;
    border: none;
    background: transparent;
}
.button-icon:hover{
	/* background: rgba(0,0,0,0.2); */
}
.button-icon:active {
	background: rgba(0,0,0,0);
}

.kado-checkbox{
    background-color: transparent !important;
}
.kado-checkbox.block .ant-checkbox-group-item {
    /* display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; */
    margin-bottom: 8px;
}
.ant-checkbox-wrapper-checked span{
    color: #44a48a !important;
}
.label {
  font-family: Lato;
  letter-spacing: 1.05px;
  color: #424343;
}
.x-large {
  font-size: 24px;
}
.large {
  font-size: 16px;
}
.medium {
  font-size: 14px;
}
.x-medium {
  font-size: 12px;
}
.small {
  font-size: 10px;
}
.x-Small {
  font-size: 9px;
}

.heavy {
  font-weight: 800;
}
.bold {
  font-weight: 600;
}
.light {
  font-weight: 400;
}
.uppercase {
  text-transform: uppercase;
}

/* .panelPlaceholderWrapper {
  text-align: center;
  padding: 25%;
  background-color: rgba(255, 255, 255, 0);
  min-width: fit-content;
}
.panelPlaceholderWrapper span {
  color: #868e96 !important;
} */
.panelPlaceholderWrapper {
   padding: 40px 25%
  
}
.panelPlaceholder {
  margin: 0.3em auto;
  /* min-width: 280px; */
}


.ant-spin.ant-spin-lg.ant-spin-spinning.ant-spin-show-text {
    
}
.Select {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #bdbdbd;
        border-radius: 0;
        outline: 0;
        width: 100%;
        font-size: 1rem;
        box-shadow: none;
        box-sizing: border-box;
        /* height: 30.59px; */
        transition: all .3s;
}
.Select-input {
    height: auto;
    padding-left: 0px;
}
.Select-input > input {
    padding: 0px 0px;
    background-color: transparent;
    border-bottom: none !important;
}

.Select-control {
    border: none;
    height: auto;
    background-color: transparent;
    border-radius: 0px;
}

.Select-control:hover {
  box-shadow: none;
}
.Select-placeholder, .Select--single > .Select-control .Select-value {
    line-height: 1;
    padding: 0;
}
.Select-arrow {
    border-color: #ddd transparent transparent;
}
.languageSwitcher.home-page .Select-arrow {
    padding-top: 7px;
}

.Select-clear-zone:hover {
    color: #ff4a52;
}
.Select-menu-outer {
    top: 36px;
    border: none;
    box-shadow: 0 2px 8px #ddd;
    background-color: #FFF;
}
.Select-option:last-child {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}
/* select dorpdown hover */
.Select-option.is-focused {
    background-color: #e8eaea;
    /* color: #FFF; */
    opacity: 0.8;
}
/* for nabavr language selector */


.Select-option.is-selected {
    color: #FFF;
}
.Select-option.is-selected .label {
    color:#fff;
}
.Select-option.is-selected.is-focused .label {
    color: #FFF;
}
.Select.is-focused:not(.is-open) > .Select-control {
  box-shadow: none;
}
.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
    letter-spacing: 1px;
}

.Select-value-label img{
    width: auto;
    height: 42px;
    position: absolute;
    top: -4px;
}
.mam-login-container  .Select-value-label img{
    position: unset;
    height: auto;    
    max-height: 20px;
    max-width: 20px;
    margin-right: 5px;
}
.mam-login-container .languageSwitcher .Select-value-label, 
.mam-login-container .languageSwitcher .Select-value-label .label, 
.mam-login-container .languageSwitcher .Select-multi-value-wrapper{
    height: unset ;
    position: unset ; 
    width: unset;
}
.mam-login-container .languageSwitcher .Select-value-label .label{
    width: 25px;
    margin-left: auto;
    margin-right: 20px;
}
.mam-login-container .languageSwitcher .Select.is-focused:not(.is-open) > .Select-control{
    background:transparent;
}
.Select-option img {
        max-height: 25px;
        max-width: 25px;
        margin-right: 10px;
}
.ant-radio-wrapper.radio-block{
	display: block;
}
.kadoRadio.ant-radio-group .ant-radio-wrapper {
	font-family: Roboto,HelveticaNeue,Arial,sans-serif;
}
.sbs-header .kadoRadio.ant-radio-group .ant-radio-wrapper {
	font-family: 'Lato' ;
	font-weight: 800;
}
/* font color */
.kadoRadio.inverted .ant-radio-wrapper {
    color: #FFF;
}
.kadoRadio.inverted .orange-inverted.ant-radio-wrapper-checked {
	background-color: #edb00a;
	border-radius: 5px;
}
.main-container .kadoRadio.inverted span {
	color: black;
	/* font-weight: bold; */
}
.kadoRadio.inverted .ant-radio-wrapper-checked span {
	color: white;
	font-weight: bold;
}
.kadoRadio.inverted .grey-inverted.ant-radio-wrapper-checked {
	background-color: #9e9e9e;
	border-radius: 5px;
}
.kadoRadio.inverted .yellow-inverted.ant-radio-wrapper-checked {
	background-color: #F6C913;
	border-radius: 5px;
}
.kadoRadio.inverted .red-inverted.ant-radio-wrapper-checked {
	background-color: #f34482;
	border-radius: 5px;
}
.kadoRadio.inverted .green-inverted.ant-radio-wrapper-checked {
	background-color: #51AF96;
	border-radius: 5px;
}
/* contianer-bg */
.kadoRadio{
    background-color: transparent !important;
}

/* round dot at center */
.kadoRadio.green.inverted .ant-radio-inner:after{
	background-color: #ffffff;
}

/* handles animation */
.kadoRadio.green.inverted .ant-radio-checked:after {
	border: 1px solid #ffffff;
}
.kadoRadio.green.inverted .ant-radio-checked .ant-radio-inner {
	border-color: #ffffff;
}


/*
grey
green
yellow
orange
red

*/

.kadoRadio .grey-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner,
.kadoRadio .grey-inverted.ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
.kadoRadio .grey-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner{
	border-color: #9e9e9e
}
.kadoRadio .yellow-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner,
.kadoRadio .yellow-inverted.ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
.kadoRadio .yellow-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner{
	border-color: #F6C913
}
.kadoRadio .orange-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner,
.kadoRadio .orange-inverted.ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
.kadoRadio .orange-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner{
	border-color: #edb00a
}
.kadoRadio .red-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner,
.kadoRadio .red-inverted.ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
.kadoRadio .red-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inne{
	border-color: #ff4a52;
}

.kadoRadio .grey-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner:after,
.kadoRadio.grey .ant-radio-checked:after,
.kadoRadio .grey-inverted.ant-radio-wrapper .ant-radio-checked:after {
	background-color: #9e9e9e;
}

.kadoRadio .yellow-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner:after,
.kadoRadio.yellow .ant-radio-checked:after,
.kadoRadio .yellow-inverted.ant-radio-wrapper .ant-radio-checked:after {
	background-color: #F6C913;
}

.kadoRadio .orange-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner:after,
.kadoRadio.orange .ant-radio-checked:after,
.kadoRadio .orange-inverted.ant-radio-wrapper .ant-radio-checked:after {
	background-color: #edb00a;
}

.kadoRadio .red-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner:after,
.kadoRadio.red .ant-radio-checked:after,
.kadoRadio .red-inverted.ant-radio-wrapper .ant-radio-checked:after {
	background-color: #ff4a52;
}

.kadoRadio .grey-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner,
.kadoRadio .green-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner,
.kadoRadio .yellow-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner,
.kadoRadio .orange-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner,
.kadoRadio .red-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner {
	background-color: #FFF;	
}

.kadoRadio label{
	/* min-width: 100%; */
	max-width: -webkit-fit-content;
	max-width: -moz-fit-content;
	max-width: fit-content;
	padding-left: 4px;
}
.radio-block.ant-radio-wrapper span:not(.ant-radio){
	max-width: 100%;
	word-break: break-word;
	white-space: pre-line;
}
.selected-option span{
	color: #44a48a !important;
}

.kado-notify.ant-notification-notice {
	padding: 6px 12px;
}

.kado-notify .ant-notification-notice-btn,
.kado-notify .ant-notification-notice-close {
	display: none;
}

.kado-notify .ant-notification-notice-with-icon .ant-notification-notice-message {
	margin-left: 32px;
}
.kado-notify .ant-notification-notice-icon {
	top: 10px;
}
.kado-notify .ant-notification-notice-icon-info {
	color: #4cb2e1;
}
.kado-notify .ant-notification-notice-icon-error {
	color: #ff4a52;
}
.kado-notify .ant-notification-notice-icon-warning {
	color: #edb00a;
}
.range_slider {
  width: 100%;
  margin-bottom: 20px;
  overflow: visible;
}

.rangeslider-horizontal {
  height: 6px;
  border-radius: 20px;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 16px;
  height: 16px;
  border-radius: 33px;
  top: 50%;
  box-shadow: none;
}

.rangeslider-horizontal .rangeslider__handle:after {
  display: none;
}

.rangeslider__labels .rangeslider__label-item {
  font-size: 12px;
}

/* slider options */
.slider_options .rangeslider__labels .rangeslider__label-item {
  display: inline-table;
}
/* slider options ends */


.quest-slider.rangeslider .rangeslider__handle-tooltip{
  position: relative;
  width: auto;
  min-width: 50px;
  height: auto;
  padding: 0em .8em .5em .8em;
  top: 100%;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
}
.sm-text-area{
  max-height: 50px !important;
  min-height: 48px !important;
}
.questions-file-preview,.questions-file-delete{
  color: #339379;
  text-decoration: underline !important;
  cursor: pointer !important;
}
.non-pointer {
  pointer-events: none;
}
.rightAligned .ant-radio-group > label{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.rightAligned span.ant-radio + *,  .rightAligned .ant-checkbox + span{
    padding-left: 0;
}

.check_box .ant-radio-wrapper .ant-radio-inner{
    border-radius: 2px;
    border-width: 2px !important;

}
.check_box .ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
    background-color: #44a48a;
}
.check_box .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after{
    position: absolute;
    display: table;
    border: 2px solid #fff !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-radius: 0;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
            transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
    background-color: transparent !important;
    top: 40%;
    left: 15%;
    height: 80%;
}

.overflow-hidden{
  overflow: hidden !important;
}
  @media screen and (min-width: 740px) {
    .report-table-div{
      /* overflow: hidden !important; */
      width: 99% !important
    }
  }


.slider-not-selected .rangeslider__handle {
    display: none;
}
.slider-not-selected .rangeslider__fill {
    background-color: #e6e6e6 !important;
}

.qs-name{
  font-weight: bold; 
}

.scale-slider {
    display: flex;
    height: calc(100vh - 25em);
    min-height: 700px;
    padding-top: 80px;
}
.ass-singleview-qst .scale-slider{
    padding-top: 30px;
}
.scale-slider .sliderdata {
    width: 60%;
    max-width: 640px;
    position: relative;
}
.scale-slider .sliderdata ul {
    padding-left: 1em;
    list-style-type: disc;
    top: -3px;
    position: relative;
}
.scale-slider .sliderdata li {
    margin-bottom: 10px;
    line-height: 1.4;
    position: relative;
}
.scale-slider .sliderdata li:before {
    /* content: ''; */
    width: 0.5em;
    height: 0.5em;
    background: rgba(0, 0, 0, 0.65);
    position: absolute;
    border-radius: 50%;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
    left: -1em;
    top: 0.1em;
}
.scale-slider .slider-userinput {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 45%;
    right: 0%;
    width: 100%;
    z-index: 1;
}
.scale-slider .sliderdata input {
    padding: 0.2em;
    max-width: 4em;
    border-radius: 0;
    border-color: #5d5c5c;
    margin: 0 0.5em;
}
.scale-slider .slider-wrap {
    width: 35%;
    max-height: calc(100% - 0%);
    position: relative;
    margin: 0 auto;
}
.scale-slider .slider-header {
    position: absolute;
    text-align: center;
    width: 60%;
    max-width: 220px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    font-size: 0.9em;
    line-height: 1.3;
}
.scale-slider .slider-header:first-of-type {
    bottom: 100%;
    -webkit-transform: translate(-50%, -5px);
            transform: translate(-50%, -5px);
}
.scale-slider .rangeslider__handle {
    height: 10px;
    background-color: #eaeaea;
}
.scale-slider .rangeslider.rangeslider-vertical {
    min-height: 90%;
    background-color: #5d5c5c;
    width: 2px;
    border-radius: 2px;
    margin: 0.3em auto;
}
.scale-slider .rangeslider-vertical .rangeslider__labels {
    margin-left: 0px;
    width: 20px;
}
.scale-slider .rangeslider-vertical .rangeslider__labels:after {
    content: '';
    width: 30px;
    height: 16px;
    background: rgb(255, 255, 255);
    display: flex;
    right: 100%;
    bottom: 0px;
    position: absolute;
}
.scale-slider .rangeslider-vertical .rangeslider__labels li {
    color: transparent;
    pointer-events: none;
    margin-left: 16px;
}
.scale-slider .rangeslider-vertical .rangeslider__labels li:before {
    left: -20px;
    -webkit-transform: translateX(calc(-50% - 5px));
            transform: translateX(calc(-50% - 5px));
}
.scale-slider .rangeslider-vertical .rangeslider__labels li.Showlabel {
    color: #5d5c5c;
    pointer-events: all;
}
.scale-slider .rangeslider-vertical .rangeslider__labels li.Showlabel:before {
    width: 20px;
}
.scale-slider .rangeslider-vertical .rangeslider__labels li.Showlabel.wide:before {
    width: 35px;
}
.scale-slider .rangeslider .rangeslider__handle {
    height: 18px;
    width: 18px;
    border-radius:50%;
    z-index: 9;
    left: 1px;
    background-color: #027E02;
    -webkit-transform: translate(-50%, -7px);
            transform: translate(-50%, -7px);
}

/* new */
html, body {
  font-size: 16px; 
}
@media (max-width:1600px){
  html, body {
    font-size: 16px; 
  }
}
@media (max-width:1366px){
  html, body {
    font-size: 14px; 
  }
}
#maincontainer {
  min-width: 300px;
  overflow: hidden;
  height: calc(100% - 4rem);
}
#maincontainer.login-mc{
  min-width: 300px;
}

.page-content {
  font-size: 1rem;
  font-weight: 500;
  height: 100%;
}
#mcPrintOff{
  height: 100vh;
  background: #e0e0e0;
  opacity: .5;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 999;
  pointer-events: none;
  top: 0;
}
.page-content.print-hide .assessmentPrintDiv{
  display: none;
}
.mc-content-wrapper{
  height: 100%;
  background-color: white;
}
.mc-wrapper:not(.login-mc) .mc-content-wrapper > div:first-child{
  min-width: 300px;
}
.qstr-scroll{
    /* padding: 0 20px; */
    /* height: calc(100% - 0px); */
    overflow: visible;
}
.qstr-scroll.overflow-visible{
    overflow: visible;
}
.mc-content-wrapper .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 2;
    /* padding: 0px 20px 0px 20px; */
    min-height: 4rem;
    border-bottom: 1px solid #dcdbdb;
}
.mc-content-wrapper .page-content .header {
    margin-bottom: 20px;
    z-index: 10;
    overflow: auto;
    position: relative;
}
.qstnr-wrapper {
  border: 1px solid #f6f6f6;
  padding: .4em 1.3em;
  cursor: pointer;
  border-radius: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fcfcfc;
  margin-right: 10px;
  height: 2.8rem;
  margin-bottom: 1em;
  font-size: 1rem;
  transition: all .3s linear;
  align-items: center;
}
.ass-tab.selected {
  color: #44a48a;
  border-color: #44a48a;
}
.main-heading,
.mc-content-wrapper .heading {
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: #585859;
  display: block;
  font-size: 1.2em;
  background-color: white;
}
.ass-qst-zoomlist > .heading,
.mc-content-wrapper .ass-ovrview-qna .heading{
  /* text-transform: lowercase; */
  color: #222;
  margin-bottom: 1em;
}
.ass-qst-zoomlist > .heading::first-letter,
.mc-content-wrapper .ass-ovrview-qna .heading::first-letter{
  /* text-transform: uppercase; */
}
.mc-content-wrapper .heading p{
  font-size: 1rem;
  margin-bottom: 5px;
}
.qa-option-wrap .rangeslider-horizontal, 
.qa-option-wrap>div:not(.btn-group):not(.rightAligned) {
  width: 97%;
}
.quest-section {
  padding: 0 10px 10px 0;
  margin: .7em 0 1.2em;
  transition: all .1s linear;
  background: #fff;
  border-bottom: 1px solid #dcdbdb;
  overflow: auto;
  overflow-y: hidden;
}
.ass-norm.content .quest-section{
 /* padding-bottom: 2em;    */
}
.kado-checkbox .ant-checkbox-group .ant-checkbox-wrapper,
.kadoRadio.ant-radio-group .ant-radio-wrapper {
  font-family: inherit;
  white-space: normal;
  word-break: break-word;
}
.quest-section .ant-radio-group label, .quest-section .kado-checkbox label {
    display: flex;
    margin-bottom: 8px;
}
.ant-checkbox+span,
.page-content .ant-radio-wrapper span {
  font-size: 1rem;
  font-weight: 500;
}
.page-footer {
  /* position: sticky;
  position: -webkit-sticky; */
  background: #fff;
  z-index: 9;
  display: block;
  padding: 0px 0px;
  /* border-top: 1px solid #dcdbdb; */
  height: 4rem;
    margin-bottom: 10px;
}
.ass-zoom + .page-footer{
    margin-bottom: 0;
    padding-bottom: 10px;
}
@media (min-width:1366px){
    /* .page-footer {
      padding-bottom: 10px;
    } */
}
.ass-norm.content,
.ass-zoom.content{
    position: relative;
    z-index: 9;
    /* height: calc(100% - (4rem + 20px + 56px)); commented for footer display*/ 
    overflow: auto;
    height: calc(100% - (4rem + 20px));
}
.ass-zoom.content {
    height: calc(100% - 8rem - 20px);
}
.mc-content-wrapper .ass-qst-zoomlist .heading {
  font-size: 1.4rem;
}
.ass-qst-zoomlist>div.quest-section {
  /* background: #fbfbfb; */
  border: 1px solid transparent!important;
  border-radius: 4px;
  padding: 10px 0px 15px ;
  margin-top: 1em;
  margin-bottom: 5px;
}
.rightAligned .ant-radio-group.kadoRadio label{
    max-width: unset;
}
.page-content .header > .heading{
    display: inline-flex;
}
.ass-ovrview-qst-sec:last-of-type .quest-section:last-of-type{
    margin-bottom: 0px;
    border-color: transparent;
    padding-bottom: 1.2em;
}
.qa-option-wrap .Select-menu-outer{
    position: absolute;
    z-index: 99;
}
.quest-section .b-margin,
.quest-section textarea,
.qa-option-wrap > input{
    margin-bottom: .7em;
}
.qstnre-bottom-help-text{
  padding-left: 20px;
}
.qstnre-bottom-help-text,
.qstnre-top-help-text {
  border-radius: 5px;
  background:#F5FDFF;
  margin:.7em 0;
  padding:.25em .45em;
  margin-right: 20px;
}
.quest-section .Select-placeholder, .quest-section .Select-value, .quest-section .Select-control > div:first-of-type,
.ant-calendar-picker-input.ant-input{
    height: 2.8rem;
}

.slider-clas-spl-pb2em{
  padding-bottom: 2em !important;
}

input,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="time"],
input[type="date"],
input[type="datetime-local"],
input[type="tel"],
input[type="number"],
input[type="search-md"],
input[type="search"],
textarea.md-textarea {
  background-color: transparent;
  height: 2rem;
  letter-spacing: 1px;
  color: #495057;
  font-size: 14px;
  font-weight: bold;
}
input[type="text"]:disabled,
input:focus:not([readonly]),
input[type="text"]:focus:not([readonly]),
input[type="password"]:focus:not([readonly]),
input[type="email"]:focus:not([readonly]),
input[type="url"]:focus:not([readonly]),
input[type="time"]:focus:not([readonly]),
input[type="date"]:focus:not([readonly]),
input[type="datetime-local"]:focus:not([readonly]),
input[type="tel"]:focus:not([readonly]),
input[type="number"]:focus:not([readonly]),
input[type="search-md"]:focus:not([readonly]),
input[type="search"]:focus:not([readonly]),
textarea.md-textarea:focus:not([readonly]) {
  border: none;
  box-shadow: none;
}
input:hover:not([readonly]),
input[type="text"]:hover:not([readonly]),
input[type="password"]:hover:not([readonly]),
input[type="email"]:hover:not([readonly]),
input[type="url"]:hover:not([readonly]),
input[type="time"]:hover:not([readonly]),
input[type="date"]:hover:not([readonly]),
input[type="datetime-local"]:hover:not([readonly]),
input[type="tel"]:hover:not([readonly]),
input[type="number"]:hover:not([readonly]),
input[type="search-md"]:hover:not([readonly]),
input[type="search"]:hover:not([readonly]),
textarea.md-textarea:hover:not([readonly]) {
  border: none;
  box-shadow: none;
}
textarea {
  background: #f9fdfc;
  overflow: auto;
}
/* .header-search-wrapper input[type=text],
.header-search-wrapper input[type=text]:hover{
    border-bottom: none;
} */
#faf-message.ant-input {
  border-bottom: none;
  background-color: #fff;
  border: 1px solid #dddcdc;
}
#faf-message.ant-input:focus,
#faf-message.ant-input:hover {
  border: 1px solid #51af96 !important;
  transition: all 0.1s;
}

.md-form {
	margin-bottom: 0px;
}
.form-group {
	margin-bottom: 10px;
}

/*input,*/
/*.form-group input,*/
.md-form input,
.form-control {
	margin-top: 0px;
	margin-bottom: 0px;
	line-height: 1;
	padding: 6px 0px;

}

.ant-slider {
	margin: 10px 0px;
	height: 16px;
}
.ant-slider-rail,
.ant-slider-track {
	height: 8px;
	border-radius: 0px;
}
.ant-slider-with-marks {
	margin: 10px 0px 25px 0px;	
}
.ant-slider-handle {
	margin-top: -6px;
    width: 20px;
    height: 20px;
}
.ant-slider-dot{
	display: none;
}
.kado-slider {
	padding: 0px 16px 0px 3px;
}
/* .panel {
    background-color: #ffffffdc;
}
.panel.panel-right {
    background-color: transparent;
}
.panel.panel-left {
    border-right: 1px solid #dddcdc;
    padding:10px 0px 0px 15px;
    background-color: transparent;
}

.paneltitleWrapper {
    border-bottom: 1px solid #dddcdc;
    padding:0px 0px 0px 15px;
    margin-left: -15px;
    margin-right: -19px;
}
.phyTitleWrapper {
    padding: 10px -1px 10px 0px;
}
.panelHeader {
    border-bottom: 1px solid #dddcdc;
    padding:14px 2px 14px 14px;
    margin-left: -15px;
    margin-right: -19px;
    cursor: pointer;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
}
.subpaneltitleWrapper {
}
.panelTitle {
    padding-top: 0px;
}
.panel .PanelTitle-sub {
    margin: 4px 0px -4px 0px;
}
.panel .panelEntryTitle {
}

.panel .Select-control {
    background-color:none ;
}
.panel .Select-input{
}
.panel .Select-placeholder{
    background-color: transparent;
}
.panel.panel-right .Select-arrow-zone {
    padding-right: 1px;
}
.panel .ant-input:focus {
    border: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
.panel.panel-right .ant-input {
    padding:0px 15px 0px 0px;
    background-color:transparent ;
}

.panel .md-form .inputContainer input {
    padding-left: 0px;
    background-color:transparent ;
}
.panel.panel-right .ant-calendar-picker-clear {
    background-color:#ffffff00;
}
.panel.panel-right .form-check {
    padding-left: 15px;
}
.panel .paneltitleWrapper .form-group {
    margin-bottom: 0px !important;
}
.panel.panel-right .button{
    min-width: 150px;
    max-width: fit-content;
}
.togler-icon{
    float: right;
    margin-right: 15px;
}
.panel .ass-sett-qn .panelEntryAss{
    margin:4px 0px
} */


.green-inverted input{
    background-color: transparent;
}
.green-inverted .ant-calendar-picker-clear{
    background: transparent;
}

.sidebarMiniIconcontainer:hover svg path{
    fill:#FFF;
}
.SidebarSecondary .kado-date-picker .green-inverted{
    color: black;
    background-color: transparent;       
}

.purple-inverted{
    color: #5c4795 !important;
}
#SidebarSecondary .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
SidebarSecondary .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label,
.main-container .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.main-container .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label,
.panel .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.panel .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
    color: #495057;
  font-size: 14px;
  font-weight: 500;
}
body, html{
    font-family: 'Roboto', sans-serif;
}

#root {
    overflow: hidden;
}
textarea, input, button, div, span 
{ outline: none !important; }

a, a:not([href]):not([tabindex]) {
    text-decoration: none;
    cursor: pointer;
}

.text-center {
	text-align: center;
}

.noPadding {
	padding: 0px !important; 
}

.autoscroll {
	overflow: auto;
}
.noscroll{
    overflow: hidden;
}

.height100 {
	height: 100%
}

.flex-colum {
	display: flex
}
.flex-colum div {
	flex-grow: 1;
}

strong, b {
	font-weight: 700;
}


/*.    HOVER.    */
.button:hover,
button:hover{
    opacity: 0.8;
    transition: all .3s
}
.button:active,
button:active{
    opacity: 7;
    transition: all .3s
}

/* Align */
.text-center {
    text-align: center;
}
.capital-first{
    text-transform: lowercase;
}
.capital-first:first-letter {
    text-transform: uppercase; 
}
/* 
.ant-dropdown-menu{
    box-shadow: 0 2px 8px #ddd;
    max-height: 50vh;
    overflow: auto;
}
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title button{
	line-height: 15px;
}
.ant-checkbox + span {
    font-size: 12px;
}
.mc-header.mc-title.mc-title-btn{
    margin-top: -4px;
    float: left;
}
.mc-title-btn .ant-switch{
    height: 30px;
    line-height: 25px;
}
.mc-header.mc-title.mc-title-btn .ant-switch:after{
    top: 5px;
}
.mc-content-div .kado-slider .ant-slider-mark{
    left:2px;
}
.kadoRadio.green.inverted .ant-radio-checked .ant-radio-inner{
    background-color:#fff !important;
}
.mc-profile .profile-dob-div .kado-date-picker.ant-calender-picker div input{
    font-size: 12px;    
    background-color: red;
} */
.ant-checkbox+span,
.kadoRadio.ant-radio-group .ant-radio-wrapper span {
    color: #6b6c6c;
}
.kado-checkbox .ant-checkbox-inner, .kadoRadio .ant-radio-inner {
    min-width: 22px!important;
    min-height: 22px!important;
    border-width: 3px!important;
    border-color: #585859;
}
.kadoRadio .ant-radio-inner:after {
    top: 25%;
    left: 25%;
}
.mc-content-wrapper .rangeslider-horizontal {
    height: 10px;
    border-radius: 10px;
    min-width: 500px;
}
.mc-content-wrapper .rangeslider, .mc-content-wrapper .rangeslider .rangeslider__fill {
    box-shadow: none;
}
.rangeslider-horizontal .rangeslider__fill {
    background-color: #44a48a!important;
}
.rangeslider__labels .rangeslider__label-item {
    font-size: inherit!important;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #44a48a;
    border-color: #44a48a;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after{
    border-color: #44a48a;
}
.kado-checkbox .ant-checkbox-inner:after {
    width: 6.2px;
    height: 12.14285714px;
}
.quest-section input,
.quest-section textarea.md-textarea{  
    border: none !important;
    border: 1px solid #D0D0D0 !important;
    padding: 7px;
    border-radius: 5px;
    min-height: 100px;
  }
  .quest-section input,
  .quest-section textarea {
    background: #fff;
    min-height: auto;
  }
.quest-section textarea{
    max-height: 110px;
    overflow: auto;
}
.ant-checkbox + span{
    position: relative;
    top: -1px;
}
.quest-section .Select-input input{
    border: none !important;
}
.quest-section .Select{
    border: none !important;

}
.quest-section .Select-control{
    border: 1px solid #D0D0D0 !important;
    border-radius: 5px;
}
.quest-section .Select-placeholder,
.quest-section .Select-value,
.quest-section .Select-control > div:first-of-type{
    display: flex;
    align-items: center;
    padding-left: 7px !important;
}
.quest-section .Select-input input{
    padding-left:0;
}
.quest-section.z-top{
    z-index: 99999;
    overflow:visible;
}
.prof-detail-postWrapper input{
    padding: 6px 0px;
}
.wiki-div {
    margin-top: 17px;
}
.wiki-div h1 {
    font-size: 1.5rem;
}
.wiki-div h2 {
    font-size: 1rem;
}
.wiki-div h3 {
    font-size: 0.75rem;
}
.react-confirm-alert-blur {
    -webkit-filter: url(#gaussian-blur);
            filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }
  
.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.8);
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  }
  
  .react-confirm-alert {
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;
  }
  
  .react-confirm-alert-svg{
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .react-confirm-alert > h1 {
    margin: 0px 0px 10px 0px !important;
    font-size: 1rem;
  }
  
  .react-confirm-alert > h3 {
    margin: 0;
    font-size: 16px;
  }
  
  .react-confirm-alert-button-group {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    float: right;
  }
  
  .react-confirm-alert-button-group > button {
    font-family: lato;
    font-weight: 500;
    outline: none;
    background: #ff4a52;
    border: none;
    display: inline-block;
    padding: 6px 18px;
    color: #eee;
    margin-right: 10px;
    border-radius: 25px;
    font-size: 12px;
    cursor: pointer;
  }
  
  @-webkit-keyframes react-confirm-alert-fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes react-confirm-alert-fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
.agreementWrapper .react-confirm-alert{
    font-family: lato;
}
@media (max-width:380px){
    .react-confirm-alert{ 
        width: 240px;
        text-align: center;
    }
}
.diaryHelpWrapper{}
.qn-reminder-div td{
    /* color: #fff;
    background-color: #dddcdc;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    text-align: center;
    padding: 0px 10px 5px 10px;
    border-radius: 10px; */
}
.qn-reminder-div td{
    color: #929292;
    /* background-color: #dddcdc; */
    border-bottom: 1px solid #dddcdc;
    border-right: 1px solid #dddcdc;
    text-align: center;
    padding: 0px 10px 5px 10px;
    border-radius: 4px;
    box-shadow: 1px 1px 1px #dddcdc;
}
.likert-section {
    margin-top: 15px;
    margin-bottom: 15px;
}

.likert-section .table thead th {
    vertical-align: middle;
    border: none;
    font-weight: bold;
    font-size: 1.1em;
}

.likert-section .table td,
.likert-section .table th {
    border: none;
    vertical-align: middle;
    font-weight: 400;
}

.likert-section .likert-table-div {
    border: 1px solid #C4C4C4;
    border-radius: 20px;
    overflow: hidden;
}

.likert-section thead tr:nth-child(even) {
    background-color: #FCFCFC;
}

.likert-section tbody tr:nth-child(odd) {
    background-color: #FCFCFC;
}
.ass-sb-button .button{
    margin: 0;
    width: 100%;
}
.range_slider {
  width: 100%;
  margin-bottom: 20px;
  overflow: visible;
}

.rangeslider-horizontal {
  height: 6px;
  border-radius: 20px;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 16px;
  height: 16px;
  border-radius: 33px;
  top: 50%;
  box-shadow: none;
}

.rangeslider-horizontal .rangeslider__handle:after {
  display: none;
}

.rangeslider__labels .rangeslider__label-item {
  font-size: 16px !important;
}

/* slider options */
.slider_options .rangeslider__labels .rangeslider__label-item {
  display: inline-table;
}
/* slider options ends */
.quest-slider.rangeslider .rangeslider__handle-tooltip{
    position: relative;
    width: auto;
    min-width: 50px;
    height: auto;
    padding: 0em .8em .5em .8em;
    top: 100%;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
    
}
.quest-slider.rangeslider .rangeslider__handle-tooltip span{
  font-size: 14px;
}
.questions-file-preview{
  color: #339379;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
}
.questions-file-delete{
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
}
.scroll-qstn-txt-area{
  overflow-y: auto !important;
}

.quest-slider .ant-drawer-content-wrapper {
	 width: 50vw !important;
	 min-width: 300px;
	 max-width: 400px;
}
.ant-drawer-body .slider-option-wrap{
    margin-bottom: 0px;
}
 @media screen and (max-width: 640px) {
	 .quest-slider .ant-drawer-content-wrapper {
		 width: 100% !important;
	}
}
 .quest-slider .ant-drawer-body {
     padding: 0;
     height: calc(100% - 65px);
     overflow: hidden;
}
.quest-slider .ant-drawer-content{
    overflow: hidden;
}
 .quest-slider .drawer-body-content {
	 position: relative;
	 display: flex;
	 flex-direction: row-reverse;
	 justify-content: center;
	 align-items: center;
	 min-height: 450px;
     height: calc(100% - 60px);
     padding: 16px 24px;
}
 .quest-slider .drawer-body-content > div {
     height: 100%;
}
 .quest-slider .quest-smiley {
	 position: absolute;
     right: 24px;
     height: calc(100% - 24px) !important;
}
 .quest-slider .rangeslider__handle-label {
     text-align: center;
     font-weight: 500 !important;
}
 .quest-slider .ant-drawer-body .quest-smiley svg {
	 width: 5.2em;
	 height: 5.2em;
}
 .drawer-slider {
	 margin: 0;
	 min-width: 3em;
	 border-radius: 7px;
	 margin-left: 1em;
}
 .drawer-slider.flat-slider.rangeslider-vertical {
	 background: #339379;
}
 .drawer-slider.flat-slider.rangeslider-vertical .rangeslider__labels {
	 width: 1em;
	 left: 100%;
	 margin-left: auto;
	 z-index: 1;
}
 .drawer-slider:not(.flat-slider) {
	 background: linear-gradient(180deg, #1a9c27 0%, #1de331 21.88%, #ffc023 41.1%, #fe8616 60.94%, #fe4f0f 79.69%, #ff0606 99.48%);
}
 .drawer-slider:not(.flat-slider).green-bottom {
	 background: linear-gradient(0deg, #1a9c27 0%, #1de331 21.88%, #ffc023 41.1%, #fe8616 60.94%, #fe4f0f 79.69%, #ff0606 99.48%);
}
 .drawer-slider.rangeslider-vertical {
	 display: flex;
}
 .drawer-slider.rangeslider-vertical .rangeslider__labels {
	 width: 3em;
	 left: -100%;
	 margin-left: -22px;
	 z-index: 1;
}
 .drawer-slider.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
     -webkit-transform: translate3d(0%, 0, 0);
             transform: translate3d(0%, 0, 0);
     font-weight: 600;
}
 .drawer-slider.rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
	 width: 0;
	 height: 0;
}
 .drawer-slider.rangeslider-vertical .rangeslider__handle {
	 width: 90%;
	 left: 50%;
	 height: 1.5em;
	 -webkit-transform: translateX(-50%);
	         transform: translateX(-50%);
	 border-radius: 0.3em;
}
 .drawer-slider.rangeslider-vertical .rangeslider__fill {
	 display: none;
	 border-radius: 7px;
}
 .modal-btn {
	 border: 1px solid #339379;
	 padding: 0.5em 0.9em;
	 transition: all 0.3s ease-in-out;
}
 .modal-btn:hover {
	 background: #339379;
	 color: #fff;
}
 .slider-btn-wrap {
	 position: absolute;
	 left: 24px;
	 display: flex;
	 flex-direction: column;
     justify-content: space-between;
     height: calc(100% - 24px) !important;
}
 .slider-btn-wrap button {
	 width: 2.6em;
	 height: 2.6em;
	 min-height: 40px;
	 min-width: 40px;
     background: #f3f2f2 !important;
     border: none;
}
.circle{
    border-radius: 50%;
}
 .slider-btn-wrap button svg {
	 width: 3.3em !important;
	 height: 3.3em;
	 max-width: 20px;
	 max-height: 100% !important;
}
 .slider-btn-wrap button:not(:hover) path {
	 stroke: #585859;
}
 .quest-sliderheader {
	 min-height: 2em;
}
 .quest-sliderheader span {
	 flex-grow: 1;
	 max-width: 90%;
	 opacity: 0;
	 pointer-events: none;
	 white-space: nowrap;
	 overflow: hidden;
	 text-overflow: ellipsis;
}
 .quest-sliderheader button {
	 background: transparent !important;
     max-height: 30px;
     border: none;
}
 .quest-sliderheader svg {
	 height: 18px;
}
 .quest-sliderheader svg * {
	 fill: #585859;
}
 .quest-sliderheader svg.close_svg {
	 height: 25px;
}
 .optionwrap .btn-group input {
	 background-color: #f3f3f3;
	 border: none !important;
     max-width: 200px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
 .optionwrap .btn-group button {
    min-width: 100px;
    border-radius: 5px;
    border: 1px solid;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background: #fff;
}

.slider-arrow {
    background-image: url(/images/slider-arrow.png);
    width: 19px;
    height: 20px;
    background-size: contain;
    position: absolute;
    top: 0;
    left: -31px;
    z-index: 5;
}
 @media only screen and (max-width: 1540px) {
	 .quest-slider .ant-drawer-body .quest-smiley .kado-svg svg {
		 width: 4.6em;
		 height: 4.6em;
	}
	 .drawer-slider {
		 font-size: 14px;
	}
	 .slider-arrow {
		 width: 16px;
		 height: 17px;
	}
}
 @media only screen and (max-width: 1280px) {
	 .quest-slider .ant-drawer-body .quest-smiley svg {
		 width: 4em;
		 height: 4em;
	}
	 .drawer-slider {
		 font-size: 13px;
	}
}

.quest-slider .drawer-footer{
    padding: 10px 24px;
    border-top: 1px solid #f0f0f0;
    height: 60px;
}
@media only screen and (max-width: 1470px) {
	.quest-slider .ant-drawer-body .quest-smiley svg {
		width: 4.5em;
		height: 4.5em;
   }
	.drawer-slider {
		font-size: 14px;
   }
}

.quest-longest-label-slider{
	margin-bottom: 5em !important;
}
display_table_cell{
    width: 100%;
    display: inline-block;
}
.login_copy_right{
    margin-top: 10px;
}
.login_copy_right div{
    color: #141414;
    font-size: 10px;
    text-align: center;
    font-weight: 600;
}
.login_copy_right a{
    color: #2B5EC2 !important;
    text-decoration: none; 
    border-bottom: none; 
    position: relative;
}
.mam-login-container{
    height: 90%;   
    align-items: center;
}
.login-form{
    border-radius: 12px;
    background: #fff;
    min-width: 300px;
    max-width: 18%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    min-height: 82%;
}
.login-footer,
.login-header{
    min-height: 18%;
    position: relative;
    padding: 20px 40px;
}
.login-form-container{
    min-height: 64%;
    padding-top: 18vh;
}
.mamebe-logo{
    background-image: url(/images/mamebe.png);
    height: 230px;
    width: 230px;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}
.asses-input{
    width: 220px;
    margin: 0 auto;
    position: relative;
    text-align: center;
}
.asses-input label{
    font-size: 14px;
    font-weight: 500;
    color: #6b6b6b;
    position:absolute;
    width: 100%;
    letter-spacing: 1.5px;
    pointer-events:none;
    transition:0.5s ease all; 
    -moz-transition:0.5s ease all; 
    -webkit-transition:0.5s ease all;
    top:0px;
    left: 0;
}
.asses-input input{
    border-radius: 0;
    font-size: 16px;
    display: inline-block;
}
.asses-input input:focus ~ label,
.asses-input input:valid ~ label {
    top:-20px;
    font-size: 13px;
    color: #51af96 !important;
    letter-spacing: 1px;
    font-weight: 600;   
}
.language-text{
    display: none;
}
.Select-menu-outer .language-text{
    display: inline;
    font-family: Roboto;
}
.login-form-container button{
    background-color: #51af96;
    color: #fff;
    border-radius: 5px;
    margin-top: 20px;
    min-width: 150px;
    font-weight: 500;
    font-size: 14px;
    max-height: 45px;
    font-family: inherit;
    letter-spacing: 1px;

}
.login-form-container button:hover{
    background-color: #44a48a;
    opacity: 1;
}
.navbar .lang-selector,
.login-header .lang-selector{
    float: right;
    width: 160px;
}
.input-asses-failed{
    padding: 15px;
    text-align: center;
    font-size: 12px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-top: 5px solid #e53935;
}

.hr-danger{
    position: relative;
    border-bottom: 5px solid red;
    top:-1px;
}

@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 568px) and (-webkit-min-device-pixel-ratio: 2){
    .login-form-container{
        margin-top: 15px;
    }
    .login-footer{
        padding: 0;
    }
    .print-btn, .pdf-dwn-btn{
        width: 25px;
        padding: 0px 5px;
    }
    .print-pdf-div{
        display: none;
    }
}

/* for chrome */
@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) { 
    .login-form-container {
        padding-top: 8vh;
    }
    .ass-button.ass-nav-btn{
        position: fixed;
        bottom: 0;
        width: 100%;
    }
    .ass-nav-btn-right{
        float: right;
    }
  }
.ass-qst-options .rangeslider-horizontal ul.rangeslider__labels > li:nth-child(1),
.ases-single-options .rangeslider-horizontal ul.rangeslider__labels > li:nth-child(1){
    -webkit-transform: translate3d(-90%, 0, 0);
            transform: translate3d(-90%, 0, 0);
    text-align: right;
    white-space: pre;
}
.ass-qst-options .rangeslider-horizontal ul.rangeslider__labels > li:last-child,
.ases-single-options .rangeslider-horizontal ul.rangeslider__labels > li:last-child{
    -webkit-transform: translate3d(-10%, 0, 0);
            transform: translate3d(-10%, 0, 0);
    text-align: left;
}
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .print-pdf-div{
        display: none;
    }

}

/* Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .print-pdf-div{
        display: none;
    }

}

/* Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .print-pdf-div{
        display: none;
    }

}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .print-pdf-div{
        display: none;
    }

}

/* Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .print-pdf-div{
        display: none;
    }

}

/* Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .print-pdf-div{
        display: none;
    }

}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 1112px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .print-pdf-div{
        display: none;
    }


}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .print-pdf-div{
        display: none;
    }


}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1112px) 
  and (max-device-width: 1112px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .print-pdf-div{
        display: none;
    }

}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .print-pdf-div{
        display: none;
    }

}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .print-pdf-div{
        display: none;
    }

}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1366px) 
  and (max-device-width: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .print-pdf-div{
        display: none;
    }

}

.reminderPage .heading, .messages .heading {
    margin-top: 17px;
}
.mc-physician .physicianInfo,
.mc-reminder .reminderPage,
.mc-files .file-content,
.mc-messages .messages,
.mc-profile .physicianInfo,
.mc-wiki .wiki-div,
.mc-fandf .physicianInfo,
.mc-diary-reminder .mc-diaryreminder-content .mc-scroll-div{
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    margin-bottom: 50px;
    padding-bottom: 126px;
}

@-moz-document url-prefix() {
    .mc-physician .physicianInfo .mc-scroll-div,
    .mc-reminder .reminderPage .mc-scroll-div,
    .mc-files .file-content .mc-scroll-div,
    .mc-fandf .physicianInfo .mc-scroll-div,
    .mc-profile .physicianInfo .mc-scroll-div{
        padding-bottom: 120px;
    }
    .mc-messages .messages .mc-scroll-div{
        padding-bottom: 180px;
    }
} @media (max-width: 991px){
        .headerContainer .sb_toogler{
            display: none;
        }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .mc-physician .physicianInfo .mc-scroll-div,
    .mc-reminder .reminderPage .mc-scroll-div,
    .mc-files .file-content .mc-scroll-div,
    .mc-fandf .physicianInfo .mc-scroll-div,
    .mc-profile .physicianInfo .mc-scroll-div{
        padding-bottom: 120px;
    }
    .mc-messages .messages .mc-scroll-div{
        padding-bottom: 180px;
    }
}
@supports (-ms-ime-align: auto) {
    .mc-physician .physicianInfo .mc-scroll-div,
    .mc-reminder .reminderPage .mc-scroll-div,
    .mc-files .file-content .mc-scroll-div,
    .mc-fandf .physicianInfo .mc-scroll-div,
    .mc-profile .physicianInfo .mc-scroll-div{
        padding-bottom: 120px;
    }
    .mc-messages .messages .mc-scroll-div{
        padding-bottom: 180px;
    }
}
.diary-reminder-list-div.mc-diaryreminder-content > div > div:nth-child(odd){
    background: #efefef;
}
.rem-lst-mc-date{
    /* display: flex !important;
    justify-content: center;
    align-items: center; */
}
.rem-lst-mc-detail{
    display: inline-flex;
    text-align: left;
    width: 300px;
    justify-content: space-between;
}
.rem-lst-mc-detail > span{
    padding-top: 3px;
}
.diary-reminder-list-entries > div{
    justify-content: space-between;
}
.diary-reminder-list-entries > div > div{
    min-height: 47px;
    display: flex;
    align-items: center;
}

.mc-header {
    min-height: 50px;
    max-height: 50px;
  }
  .headerContainer {
    min-height: 50px;
    /* max-height: 50px; */
    border-bottom: 1px solid #dddcdc;
    justify-content: center;
    display: flex;
    /* background-color: #dddd; */
  }
  .diaryHeader {
    margin-top: 14px;
  }
  .diaryHeader.diaryHeaderdate {
    max-width: 110px;
    min-width: 110px;
    border-right: 1px solid #f4f4f4;
  }
  .diaryTime {
    min-width: 50px;
    max-width: 50px;
    padding: 5px 0px 0px 0px;
  }
  .diaryBulletBox {
    max-width: 30px;
    padding: 5px 2px 0px 0px;
  }
  .diaryDescBox {
    padding: 5px 0px 0px 0px;
  }
  .diaryActionsBox {
    max-width: 90px;
    text-align: right;
  }
  .diaryActionsBox button {
    margin: 5px;
  }
  
  .diaryLoadMoreBtn {
    display: block;
    width: 100%;
    padding: 50px 0px;
    /*cursor: pointer;*/
    background-color: #fff;
    border: none;
    margin-bottom: 50px;
    font-weight: bold;
    text-transform: uppercase;
    color: #4cb2e1;
    pointer-events: none;
  }
  .diaryLoadMoreBtn:active,
  .diaryLoadMoreBtn.active {
    background-color: #fff;
  }
  .diary-editor .ant-time-picker-input {
    display: block;
  }
  .diary-editor input {
    padding: 6px 1px;
  }
  .sb_toogler {
    cursor: pointer;
    height: 40px;
    float: right;
  }
  .mc-header.mc-title {
    padding: 15px 0px 12px 5px;
  }
  
  .diary-entry-group .ant-slider-mark-text{
      width: 4.5% !important;
      margin-left: -2.25% !important;
  }
  .diary-entry-group .ant-slider-mark {
      left:3px ;
  }

  @media (max-width: 490px) {
        .headerContainer{
            display: block;
        }
   
  }
.navbar {
  min-height: 4rem;
  /* max-height: 4rem; */
  padding: 5px 20px 0px 20px;
  background-color: #fff;
  z-index: 11;
  box-shadow: none;
}
.navbar.nobshadow{
  box-shadow: none;
}
.languageSwitcher {
  width: 80px;
}
.languageSwitcher .Select-menu-outer{
    min-width: 180px;
}

/* for language selector */
.languageSwitcher .Select,
.languageSwitcher .Select.is-open {
  border-bottom: none;
}
.navbar .languageSwitcher .Select .Select-input {
  padding-left: 0px;
}
.navbar .Select{
    height: 2.8rem;
    display: flex;
    align-items: center;
    padding: 0 !important;

}
@media only screen and (max-width: 380px) {
    .languageSwitcher{
        min-width: unset;
        max-width: 50px;
    }
    .navbar .Select-menu-outer {
        max-width: 150px;
        position: fixed;
        top: 3rem;
        right: 0 !important;
        left: 0%;
        min-width: 100%;
    }
}
.mam-login-container .languageSwitcher{
    width: 100%;
    max-width: 300px;
}
.mam-login-container .languageSwitcher .Select-control{
    background: transparent;
}
/* navabr-reminder */
.nb-rem-dropdown {
  border-bottom: 1px solid #ddd;
}
.nb-rem-dropdown:last-child {
  border: none !important;
}
.nb-rem-dropdown:hover {
  background-color: #e8eaea;
}
.navbar-reminder .ant-dropdown-menu-item {
  padding: 0px;
}
.nb-rem-items {
  padding: 6px;
}
.nb-rem-dropdown .btn-group {
  box-shadow: none;
  margin: 6px 0px 12px 0px;
}
@-moz-document url-prefix() {
  .navbar {
    min-height: 65px;
    max-height: 65px;
    border-bottom-style: solid;
    border-width: 0.1px;
    padding: 20px 10px;
    border-bottom: 0.1px solid #dddcdc;
    background-color: #fff;
    z-index: 20;
    
  }
}
.ant-dropdown-menu.navbar-profile .ant-dropdown-menu-item-active > a {
  color: #fff;
}
.navbar-profile .ant-dropdown-menu-item {
  line-height: 30px;
}

.toast_container {
  border-radius: 4px;
  padding: 8px 15px;
}
.cankado_toast button {
  position: relative;
  top: 6px;
}

.toast_container.success {
  background-color: #51af96;
}

.toast_container.info {
  background-color: #59a3e9;
}

.toast_container.error {
  background-color: #f34482;
}
.toast_container div {
  font-size: 13px;
}
@media (max-width: 425px) {
  .languageSwitcher {
    /* display: none; */
  }
}
@media (max-width: 490px) {
  .navbar {
    padding-left: 6px;
  }
  .navbar-title {
    /* max-width: fit-content;
    min-width: fit-content; */
  }
}
.languageSwitcher .Select-control {
    height: 100%;
    background: #F6F6F6;
    border-radius: 5px;
    padding: 5px;
}
.languageSwitcher .Select.is-focused:not(.is-open) > .Select-control{
    background: #F6F6F6;
}
.languageSwitcher .Select-value-label,
.languageSwitcher .Select-value-label .label,
.languageSwitcher .Select-multi-value-wrapper{
    height: 100%;
    position:absolute;
    width: 100%;
}
.languageSwitcher .Select-value-label img{
  width: 40px;
  height: 40px;
  position: absolute;
  align-items: center;
  top: -4px;
}
.languageSwitcher .Select-arrow-zone{
    padding: 0;
    width: 1em;
}
.languageSwitcher .label,
.languageSwitcher .Select-value-label{
    display: block;
}
.languageSwitcher .label{
    height: 126% !important;
}

.languageSwitcher ::-webkit-scrollbar {
  display: none;
}
.error_top{
    display: flex;
    height: 85%;
}

.error_bottom{
   
    height: 15%;
}
.display_flex{
    display: flex;
    align-items: center;
}

.main_error_div {
    margin: 0 auto;
}
.error_img img{
    margin-left: auto;
    margin-right:15px;
    margin-top: 32px;
}
.error_top .row {
    width: 100% !important;
    margin: 0px !important;
}
.four_not_four{
    font-size: 160px;
    font-weight: 700;
    color: #2B5EC2;
    line-height: 157px;
}
.line{
    width: 100%;
    height: 1px;
    background-color:#2B5EC2;
}
.oops_div {
    color:#262525;
    font-size: 15px;
    font-weight: 600;
    margin-top:10px;
}
.not_available{
    color: #A6A6A6;
    font-size: 11px;
    font-weight: lighter;
    letter-spacing: 0.5px;
}
.error_table{
    display: table;
}
.error_table_cell{
    display: table-cell;
    vertical-align: middle;
}
.error_term_condition {
    font-size: 9px;
    color: #9F9F9E;
    text-align: center;
}
.error_login_copy_right div {
    color: #141414;
    font-size: 10px;
    text-align: center;
    font-weight: 600;
}
.main-container {
  border: none;
  box-shadow: none;
  overflow: hidden;
}
.dashboard-container {
  height: 100%;
  overflow: hidden;
  border: none;
  box-shadow: none;
}
.quick-container {
  min-width: 250px;
  max-width: 30%;
  padding: 0px;
}

.diary-container {
  padding-right: 0px;
}
.textCenter {
  text-align: center;
}
@media (min-width: 576px) {
  .dashboard-container {
    min-width: 100%;
  }
}
.mc-content-div {
  overflow: hidden;
  overflow-y: auto;
  height: 100vh;
}
.mc-bottom-btn {
  padding: 0px 10px 5px 0px;
}

.offline_toast {
  position: absolute;
  top: 12px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #777474;
  color: #fff;
  z-index: 1002;
  padding: 10px 50px;
  border-radius: 4px;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.26);
}
.offline_toast img {
  margin-right: 10px;
  width: 15px;
  vertical-align: text-bottom;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .dashboard-container {
    left: 0px !important;
  }
  .sb_toogler{
    width: 40px;
  }
  @media (min-width: 576px) {
    .dashboard-container {
      padding-left: 0px !important;
    }
  }
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main-con-wrapper{
  /* position: fixed;
  top: 50px; */
}
.fullscreen-btn{
    min-width: 2.8rem;
    min-height: 2.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
}
@media (max-width:1366px){
    .fullscreen-btn{
        top:64px;
    }
}
@-moz-document url-prefix() {
    .fullscreen-btn{
        top:74px;
    }
}
.login-mc + .fullscreen-btn{
    display: none;
}
#dashboard .nav-actions{
    padding-right: calc(1rem + 38px);  /*58-20px of navbar padding*/
}
.mc-content-wrapper{
    /*padding-left: calc(1rem + 58px);*/ /*43px of logo + 1rem */
    overflow: hidden !important;
}
.page-content > div{
    padding-left: calc(1rem + 58px);
    padding-right: calc(1rem + 58px);
}
.mc-content-wrapper .page-content .header {
    margin-left: calc(1rem + 58px);
    margin-right: calc(1rem + 58px);
    padding: 0;
}
.nd_p-l{
    padding-left: calc(1rem + 58px);
}
.nd_p-r{
    padding-right: calc(1rem + 58px);
}
.nd_m-r{
    margin-right: calc(1rem + 58px);
}
.nd_m-l{
    margin-right: calc(1rem + 58px);
}
.page-footer .button.btn-with-brand{
    min-width: 100px !important;
}
@media (max-width: 1200px){
    .navbar,
   .page-content > div:not(.header){
    padding-left: calc(1rem + 18px) !important;
    padding-right: calc(1rem + 18px) !important;
    } 
    .mc-content-wrapper .page-content .header {
    margin-left: calc(1rem + 18px);
    margin-right: calc(1rem + 18px);
    }
    #dashboard .nav-actions{
        padding-right: calc(0rem + 0px)
    }
}

@media (max-width: 740px){
.navbar,
   .page-content > div:not(.header){
    padding-left: calc(1rem + 0px) !important;
    padding-right: calc(1rem + 0px) !important;
    } 
    .mc-content-wrapper .page-content .header {
    margin-left: calc(1rem + 0px);
    margin-right: calc(1rem + 0px);
    }
    #dashboard .nav-actions{
        padding-right: calc(0rem + 0px)
    }
    .scale-slider{
        min-height: 680px;
    }
}
@media (max-width: 576px){
    .btn-wrap .button{
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    }
}
@-webkit-keyframes jump {
    0%   {-webkit-transform: translate3d(0,0,0) scale3d(1,1,1);transform: translate3d(0,0,0) scale3d(1,1,1);}
    40%  {-webkit-transform: translate3d(0,30%,0) scale3d(.7,1.5,1);transform: translate3d(0,30%,0) scale3d(.7,1.5,1);}
    100% {-webkit-transform: translate3d(0,100%,0) scale3d(1.5,.7,1);transform: translate3d(0,100%,0) scale3d(1.5,.7,1);}
}
@keyframes jump {
    0%   {-webkit-transform: translate3d(0,0,0) scale3d(1,1,1);transform: translate3d(0,0,0) scale3d(1,1,1);}
    40%  {-webkit-transform: translate3d(0,30%,0) scale3d(.7,1.5,1);transform: translate3d(0,30%,0) scale3d(.7,1.5,1);}
    100% {-webkit-transform: translate3d(0,100%,0) scale3d(1.5,.7,1);transform: translate3d(0,100%,0) scale3d(1.5,.7,1);}
}
.cl-container {
    text-align: center;
}
.cl-logo {
    position: absolute;
    bottom: 50%;
    height: 50px;
}
.jump {
    /*transform-origin: 50% 50%;*/
    position: absolute;
    bottom: 20%;
    height: 50px;
    -webkit-animation: jump .5s linear alternate infinite;
            animation: jump .5s linear alternate infinite;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .cl-container {
        padding: 0 50%;
    }   
}
