.likert-section {
    margin-top: 15px;
    margin-bottom: 15px;
}

.likert-section .table thead th {
    vertical-align: middle;
    border: none;
    font-weight: bold;
    font-size: 1.1em;
}

.likert-section .table td,
.likert-section .table th {
    border: none;
    vertical-align: middle;
    font-weight: 400;
}

.likert-section .likert-table-div {
    border: 1px solid #C4C4C4;
    border-radius: 20px;
    overflow: hidden;
}

.likert-section thead tr:nth-child(even) {
    background-color: #FCFCFC;
}

.likert-section tbody tr:nth-child(odd) {
    background-color: #FCFCFC;
}